// // import React, { useEffect, useState } from 'react'
// // import Layout from '../../../gatsby-theme-minimal/src/components/Layout/Layout'
// // // import { Flex } from '@theme-ui/components'
// // import { graphql } from 'gatsby'
// // import { useColorMode } from 'theme-ui'
// // import checkForCustomLinks from '../../../gatsby-theme-minimal/src/helpers/checkForCustomLinks'
// // // import importPageComponents from '../helpers/importPageComponents'
// // // import ContactFormV1 from '../stories/ContactFormV1'
// // import GlobalStyles from '../../../gatsby-theme-minimal/src/gatsby-plugin-theme-ui/GlobalStyles'
// // // import SEO from '../components/Layout/seo'
// // import '../../../gatsby-theme-minimal/src/index.css'
// // // import 'react-image-lightbox/style.css'
// // const shortid = require('shortid')
// // // import Navigation from '../../../gatsby-theme-minimal/src/components/Layout/Header/Navigation/v6/Navigation'

// // export default function CookingWithUs({ pageContext, data }) {
// //   const [themeMode, setThemeMode] = useColorMode('') // useColor must be used in a hook
// //   const [components, setComponents] = useState(null) // where the pages componenets are stored after dynamically importing

// //   // ===== Destructing and variable Creation =====

// //   const { businessData } = data
// //   const configData = data.allConfigData.edges[0].node
// //   const { metaTitle, metaDescription, keywords } = configData
// //   const { name, desc } = businessData

// //   // PAGE Context data that comes from gatsby node file gets desctructed from here
// //   const {
// //     // gonationID,
// //     pageObjectKey,
// //     locationIndex,
// //     // city,
// //     pageComponents,
// //     customPageName,
// //     locations,
// //   } = pageContext

// //   // this determines the page title used in the navigation.
// //   // if its custom page name use it. if not use the pageobject keywhich is the default name
// //   const pageTitle = `${customPageName !== '' ? customPageName : pageObjectKey}`
// //   const isMultiLocationSite = false
// //   // =============================================
// //   return (
// //     <Layout
// //       {...pageContext}
// //       navLinks={checkForCustomLinks(configData)}
// //       businessData={businessData}
// //       configData={configData}
// //       isMultiLocationSite={isMultiLocationSite}>
// //       {/* <Navigation /> */}

// //       <GlobalStyles
// //         businessData={businessData}
// //         configData={configData}
// //         {...pageContext}
// //       />
// //     </Layout>
// //   )
// // }

// import PageTemplate from '../../../gatsby-theme-minimal/src/templates/PageTemplate'
// import React from 'react'

// export default function CookingWithUs() {
//   const pageContext = {
//     gonationID: 'bzn-1bOr5X2wS5qveyneqD8tEg',
//     pageComponents: [],
//     city: 'milford',
//     locationIndex: 0,
//     pageObjectKey: 'cooking-with-us',
//     customPageName: '',
//     locations: ['milford'],
//   }

//   return <PageTemplate pageContext={pageContext} data={query}></PageTemplate>
// }

// // graph ql page query is here
// export const query = graphql`
//   fragment optionFieldsCookingWithUsTemplate on componentOptions {
//     title
//     titles
//     subtitle
//     subtitles
//     text
//     texts
//     image
//     images
//     date
//     dates
//     caption
//     captions
//     interval
//     background
//     backgrounds
//     backgroundColor
//     backgroundColors
//     innerBackgroundColor
//     backgroundImage
//     backgroundImages
//     link
//     links
//     ctaName
//     ctaNames
//     ctaLink
//     ctaLinks
//     ctaClassName
//     containerStyles
//     shoutContainerStyles
//     removeAlbums
//     logoHero
//     specialEventsOnly
//     menuImages
//     popUpContentBoxes
//     contactDetails
//     onlineOrderLinks
//     onlineOrderCTAName
//     onlineOrderClassName
//     reservationLinks
//     reservationCTAName
//     reservationClassName
//     deliveryLinks
//     deliveryCTAName
//     deliveryClassName
//     phoneHidden
//     directionsHidden
//     poweredIDs
//     containerID
//     onlineOrderSectionAnchorPath
//     socialLinksHidden
//     callSectionAnchorPath
//     directionSectionAnchorPath
//     reservationSectionAnchorPath
//     deliverySectionAnchorPath
//     hideNavLinks
//     restrictCharacters
//     removeSectionNavLinks
//     quotes
//     galleryData
//     email
//     phoneNumbers
//     addresses
//     emails
//     linkedin
//     pinterest
//     navigationLogo
//     scriptWidget
//     scriptId
//     menuItemLinkNames
//     menuItemLinks
//     video
//     eventTagsToShow
//     eventTagsToHide
//   }

//   fragment pageComponentFieldsCookingWithUsTemplate on pageComponent {
//     componentName
//     variantName
//     options {
//       ...optionFields
//     }
//   }

//   query CookingWithUsTemplateQuery($city: String) {
//     businessData(city: { eq: $city }) {
//       city
//       desc
//       links {
//         facebook
//         instagram
//         twitter
//         website
//         youtube
//       }
//       id
//       loc
//       name
//       phone
//       slug
//       state
//       street
//       zip
//       cover {
//         imageBaseUrl
//         isDefault
//         imagePrefix
//       }
//       avatar {
//         imageBaseUrl
//         imagePrefix
//         isDefault
//       }
//       hours {
//         mon {
//           close
//           name
//           open
//         }
//         tue {
//           close
//           name
//           open
//         }
//         wed {
//           close
//           name
//           open
//         }
//         thu {
//           close
//           name
//           open
//         }
//         fri {
//           close
//           name
//           open
//         }
//         sat {
//           close
//           name
//           open
//         }
//         sun {
//           close
//           name
//           open
//         }
//       }
//     }
//     allConfigData {
//       edges {
//         node {
//           themeName
//           metaTitle
//           metaDescription
//           alternativeGoogleMapLink
//           navigation {
//             version
//             variantName
//             additionalNavLinks {
//               ctaName
//               ctaLink
//               className
//               order
//             }
//             options {
//               ...optionFieldsPageTemplate
//             }
//           }
//           footer {
//             version
//             variantName
//             additionalNavLinks {
//               ctaName
//               ctaLink
//               className
//               order
//             }
//             options {
//               ...optionFieldsPageTemplate
//             }
//           }
//           ctaWidget {
//             version
//             variantName
//             options {
//               ...optionFieldsPageTemplate
//             }
//           }
//           contactForm {
//             componentName
//             variantName
//             options {
//               ...optionFieldsPageTemplate
//             }
//           }
//           home {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           about {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           menu {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           events {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           gallery {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           contact {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

import React from 'react'

export default function WHAT() {
  return <div>tester</div>
}
